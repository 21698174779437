import { ethers } from "ethers";

// const decimals = Number(process.env.REACT_APP_DECIMALS);
// const decimals = 18;

// value must be string
export function ParseUnit(value, decimals) {
  return ethers.utils.parseUnits(value, decimals);
}

export function FormatUnit(value, decimals) {
  return ethers.utils.formatUnits(value, decimals);
}

export function ConvertTrueBool(value) {
  return value.toLowerCase() === "true";
}

export function ConvertCloseFund(value) {
  if (value.toLowerCase() === "close fund") {
    return true;
  } else {
    return false;
  }
}
export function ConvertDisableDP(value) {
  if (value.toLowerCase() === "disable depositing") {
    return true;
  } else {
    return false;
  }
}
export function ConvertDisableWT(value) {
  if (value.toLowerCase() === "disable withdrawing") {
    return true;
  } else {
    return false;
  }
}

export function ConvertDisableCancelDP(value) {
  if (value.toLowerCase() === "disable cancel depositing") {
    return true;
  } else {
    return false;
  }
}
export function ConvertDisableCancelWT(value) {
  if (value.toLowerCase() === "disable cancel withdrawing") {
    return true;
  } else {
    return false;
  }
}
