import WalletConnect from "@walletconnect/web3-provider";

export const providerOptions = {
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: "4b3de7362dc14a1f91c4e8efc4bed76b", // required
    },
    qrcodeModalOptions: {
      mobileLinks: [
        "metamask",
        "trust",
        "argent",
        "rainbow",
        "imtoken",
        "pillar",
      ],
    },
  },
};
